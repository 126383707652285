import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma d’estrella, de 3 a 6 cm de diàmetre quan està obert. Està dividit en 5-9 lacínies triangulars i molt corbades, de color blanquinós. La capa miceliana és de color marró i coberta de terra, capa fibrosa de color crema blanquinosa. La part interior és de color crema, d’1,5 a 3 cm en forma de sac arrodonit amb una perforació apical per on surten les espores. Aquestes són de color groc a marró pàl·lid en massa, esfèriques, un poc eriçonades, de 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      